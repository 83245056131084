import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_OpenHAB_Tutorials/PrimaryBox';
import PrimaryBox from 'components/Advanced_User/OpenHAB_3_Camera_Binding/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "OpenHAB 3 INSTAR Camera Binding",
  "path": "/Advanced_User/OpenHAB_3_Camera_Binding/",
  "dateChanged": "2020-12-30",
  "author": "Mike Polinowski",
  "excerpt": "The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_OpenHAB.png",
  "social": "/images/Search/AU_SearchThumb_OpenHAB.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_OpenHAB2_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "OpenHAB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='OpenHAB 3 INSTAR Camera Binding' dateChanged='2020-12-30' author='Mike Polinowski' tag='INSTAR IP Camera' description='The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_OpenHAB.png' twitter='/images/Search/AU_SearchThumb_OpenHAB.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/OpenHAB_3_Camera_Binding/' locationFR='/fr/Advanced_User/OpenHAB_3_Camera_Binding/' crumbLabel="OpenHAB" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "openhab-3-instar-camera-binding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#openhab-3-instar-camera-binding",
        "aria-label": "openhab 3 instar camera binding permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB 3 INSTAR Camera Binding`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#add-a-camera-thing"
        }}>{`Add a Camera Thing`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#installing-ffmpeg"
            }}>{`Installing FFMPEG`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#add-an-snapshot-channel"
        }}>{`Add an Snapshot Channel`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#camera-control"
        }}>{`Camera Control`}</a></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "add-a-camera-thing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#add-a-camera-thing",
        "aria-label": "add a camera thing permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a Camera Thing`}</h2>
    <p>{`After installing the camera binding we can continue with adding our camera. Go to `}<strong parentName="p">{`Things`}</strong>{` and select the camera binding (install it if it is missing by clicking on `}<strong parentName="p">{`Install More Bindings`}</strong>{`):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8fe825d5bae150a17d3d015667133bb7/20751/OpenHAB3_Docker_20.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABLUlEQVQY02XNS07DMBSF4ewhseM86jiOnTQlj9LSFiRggBBiAUggJsxZCSMGDNnsjxqCQGLw6fieK/kGRVGw2Wzouo7D4YD3HmcNTe2xVYXzHl/XU7arFWe7HeM44pyb+r9cXROkaUrTNCzblr7vybVmmeXcDmsu2xW70rLRBdvCTDlmC9YL/T3Pft57XRC0ywZXWWIpUFIipGRIMx4ur7nfnnHhavbGsi8rDkfWsdWGMc1ZHz+f5NOhc5UQbC+uuLq5o+lPqdoe25yQZDnDsKY76TDGYEw5KY/Kcu7MNOf5giRJSdMMEccE+uWD4fUT9fxO9PSOenxDaI+MQmKVoCbqW1agtEct7J/+N6WQBK5uWK46KuexzlNWjkgIwjAkDKM5Z5EgFDGhkP93sy+e5apYpm20LwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8fe825d5bae150a17d3d015667133bb7/e4706/OpenHAB3_Docker_20.avif 230w", "/en/static/8fe825d5bae150a17d3d015667133bb7/d1af7/OpenHAB3_Docker_20.avif 460w", "/en/static/8fe825d5bae150a17d3d015667133bb7/7f308/OpenHAB3_Docker_20.avif 920w", "/en/static/8fe825d5bae150a17d3d015667133bb7/d0853/OpenHAB3_Docker_20.avif 1037w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8fe825d5bae150a17d3d015667133bb7/a0b58/OpenHAB3_Docker_20.webp 230w", "/en/static/8fe825d5bae150a17d3d015667133bb7/bc10c/OpenHAB3_Docker_20.webp 460w", "/en/static/8fe825d5bae150a17d3d015667133bb7/966d8/OpenHAB3_Docker_20.webp 920w", "/en/static/8fe825d5bae150a17d3d015667133bb7/7f429/OpenHAB3_Docker_20.webp 1037w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8fe825d5bae150a17d3d015667133bb7/81c8e/OpenHAB3_Docker_20.png 230w", "/en/static/8fe825d5bae150a17d3d015667133bb7/08a84/OpenHAB3_Docker_20.png 460w", "/en/static/8fe825d5bae150a17d3d015667133bb7/c0255/OpenHAB3_Docker_20.png 920w", "/en/static/8fe825d5bae150a17d3d015667133bb7/20751/OpenHAB3_Docker_20.png 1037w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8fe825d5bae150a17d3d015667133bb7/c0255/OpenHAB3_Docker_20.png",
              "alt": "OpenHAB 3 INSTAR Camera Binding",
              "title": "OpenHAB 3 INSTAR Camera Binding",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select the INSTAR preset for your camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "783px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7a0474c6bffce5df1d66040e31ac620e/e51a6/OpenHAB3_Docker_21.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABkElEQVQoz2XQy27TQBSAYZcNEWrr8YztmbHn4rnY8SVt7UQ4thNK0kuyqZCQkHiArooQgofiAeAJUdSCWvPp19nMnM1xSqMS9YJWqhtWbb9ev98Ol5tmvkgSqf4jtHbmTVOOVaWVhQxzScoEV1NbVtX4R1WZLHOCMAyCwP8nCHxwQvdfkx+/i28/+fdfeL5Hp2/8IPSfQygk1AEAuK4LAIBPEASnwfk13X2Rt/f49sE359A9gQjB5zzPx8SJ4lgJGVEKPYAOqxBBhI4n8PWRN3kFJ0fIPUYI/X16yvNAgInz4eziYzdcFWUdkhqTx3mBo5rGNYlqEjeEjqoJ7TGhhDqfFsv9ZnszDNfDqsmLSpuZTmfaVsbOjD2zWZGolPGMi8emXBghLrngmDp3bff5anPXLnuTtonutF1qc0iZNtFtohdCNoy/iIsmYiEmzvxt+2677fohLyupjTJWGavTTNuUSUliFnExKhaSxPHhYP2y393sVsO6a7s8z1ObTtOpYJwxLrgQXHDGRwkuWHRY/gMxNVIjvDG/dAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7a0474c6bffce5df1d66040e31ac620e/e4706/OpenHAB3_Docker_21.avif 230w", "/en/static/7a0474c6bffce5df1d66040e31ac620e/d1af7/OpenHAB3_Docker_21.avif 460w", "/en/static/7a0474c6bffce5df1d66040e31ac620e/44aa8/OpenHAB3_Docker_21.avif 783w"],
              "sizes": "(max-width: 783px) 100vw, 783px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7a0474c6bffce5df1d66040e31ac620e/a0b58/OpenHAB3_Docker_21.webp 230w", "/en/static/7a0474c6bffce5df1d66040e31ac620e/bc10c/OpenHAB3_Docker_21.webp 460w", "/en/static/7a0474c6bffce5df1d66040e31ac620e/b408c/OpenHAB3_Docker_21.webp 783w"],
              "sizes": "(max-width: 783px) 100vw, 783px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7a0474c6bffce5df1d66040e31ac620e/81c8e/OpenHAB3_Docker_21.png 230w", "/en/static/7a0474c6bffce5df1d66040e31ac620e/08a84/OpenHAB3_Docker_21.png 460w", "/en/static/7a0474c6bffce5df1d66040e31ac620e/e51a6/OpenHAB3_Docker_21.png 783w"],
              "sizes": "(max-width: 783px) 100vw, 783px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7a0474c6bffce5df1d66040e31ac620e/e51a6/OpenHAB3_Docker_21.png",
              "alt": "OpenHAB 3 INSTAR Camera Binding",
              "title": "OpenHAB 3 INSTAR Camera Binding",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now configure the `}<strong parentName="p">{`Thing`}</strong>{` with your camera IP, ONVIF server port (`}<em parentName="p">{`default 8080`}</em>{` - `}<a parentName="p" {...{
        "href": "https://wiki.instar.com/en/Web_User_Interface/1080p_Series/Network/ONVIF/"
      }}>{`make sure to deactivate authentication in your camera's webUI`}</a>{`) and your camera admin login:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "757px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/62417d53934178759a29981a31419371/1fbe8/OpenHAB3_Docker_22.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "120.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAYCAIAAAB1KUohAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACZUlEQVQ4y42U2XajMAyG/QgFjOVVMjZLoFmahPakvZiruZ7bef83mWOTSdNJ2s7Hz2YQkoVkto7NKjQ+hCY0FyhzPB5Pp1Pf94hIH5kCrQMxZxNOqFFpzDjnlFJSSkSMMaorpJTWWiKSEqSULIQYYph+Po0/NgqU1uZrGWO01i6F4lmMXYhx//u0+/WspbHOWfuNjDExdsOwYgCgtZEVQCXSpzMmezDGWGsXV7cAAFNKbTYb3zQXn8bYtu02m+04PQ7Dyvvmrv9krI09Ho6IZIxDJESyDqfpcZ6fY9tZex68lnPYpkeWCQHb7XYYBkQCkABSCECkEAJASqmUchlflG+BiJRUjHOx2+2e9k/TOBZFUZ4piqKoqqq8R5Epy5IJgBjjahxVzpR9x9mPGGOIfNf3Xdf1fa+UYnUtnuf59fX15eVFa/3w8FB+SfWXsirZMsI5r2v+WZzXlk0I/TCQ91VVMV7XsU0bCFjmef7wp/bl5YVkPE7Ter323i9VsUzv2yiSsTZmfzgYYwGkUnrRf1S4TY0hlVqvNyGGkPcmJDz5pmk8ee+bxvvchemayOcL7xzWdc0457GNRGitWVoSCQGgrmuRdRfOeQ5bm7e3t2l6/Ceqi+4WtnMoZf7PXde1bev9OSQiXKLMZwIAcUNd11XFmdJ6nmfMq8eV83ekVBdl0giRTxUmpRrHyTm8VZq9WxamJCLKSVYAknyjlE5d1XctIpqbcnYO8/Tc+QZRay0ECJH6jXPOlNKH/SGEmHJjPlt3PiRPa9N1Q+rnivOU3OQAFw8pRndOabK0lnNepfJPx+v6/QPLV5rMHKYWKAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/62417d53934178759a29981a31419371/e4706/OpenHAB3_Docker_22.avif 230w", "/en/static/62417d53934178759a29981a31419371/d1af7/OpenHAB3_Docker_22.avif 460w", "/en/static/62417d53934178759a29981a31419371/4313e/OpenHAB3_Docker_22.avif 757w"],
              "sizes": "(max-width: 757px) 100vw, 757px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/62417d53934178759a29981a31419371/a0b58/OpenHAB3_Docker_22.webp 230w", "/en/static/62417d53934178759a29981a31419371/bc10c/OpenHAB3_Docker_22.webp 460w", "/en/static/62417d53934178759a29981a31419371/4578a/OpenHAB3_Docker_22.webp 757w"],
              "sizes": "(max-width: 757px) 100vw, 757px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/62417d53934178759a29981a31419371/81c8e/OpenHAB3_Docker_22.png 230w", "/en/static/62417d53934178759a29981a31419371/08a84/OpenHAB3_Docker_22.png 460w", "/en/static/62417d53934178759a29981a31419371/1fbe8/OpenHAB3_Docker_22.png 757w"],
              "sizes": "(max-width: 757px) 100vw, 757px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/62417d53934178759a29981a31419371/1fbe8/OpenHAB3_Docker_22.png",
              "alt": "OpenHAB 3 INSTAR Camera Binding",
              "title": "OpenHAB 3 INSTAR Camera Binding",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "installing-ffmpeg",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#installing-ffmpeg",
        "aria-label": "installing ffmpeg permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installing FFMPEG`}</h3>
    <p>{`The camera binding is using FFMPEG to provide video streams and snapshots from your camera. Make sure that FFMPEG is installed on your system by running `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ffmpeg -version`}</code>{`. Also verify the installation path - default `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/usr/bin/ffmpeg`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "765px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9e1080732d3f380b9ab08b6698ee2b25/bbb77/OpenHAB3_Docker_23.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.21739130434782%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA60lEQVQY04WQwXKDMAxE/Qlg2QYkW7KBhNCZpIkJ6aX5/9/qAA2TQ2f6Zg+rvawkBQCn03g+nz/G0VprXgCA/g8FAF1/GI7DMAzMEhaYmeu6BgBj7KqdzVsAozUo59z8+Hp+P3POSIQNIf6KvCf6Q9hQfziGEJQxhllSaoUl+KWTA/PaLizrLmu0JSLCUWJEImPM0ny93abpnvPUdT0tfeFN/t1sJ4UQUtsiojLG5pwf83yf58vlM6U2xSQcV1KMSWKMEkViSsl7X7nKuYpZmqZRWuu6rokQsfGeqqqCF/vb91FrXa4URVGW5Q96Li1BAUuLdgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e1080732d3f380b9ab08b6698ee2b25/e4706/OpenHAB3_Docker_23.avif 230w", "/en/static/9e1080732d3f380b9ab08b6698ee2b25/d1af7/OpenHAB3_Docker_23.avif 460w", "/en/static/9e1080732d3f380b9ab08b6698ee2b25/23eaf/OpenHAB3_Docker_23.avif 765w"],
              "sizes": "(max-width: 765px) 100vw, 765px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9e1080732d3f380b9ab08b6698ee2b25/a0b58/OpenHAB3_Docker_23.webp 230w", "/en/static/9e1080732d3f380b9ab08b6698ee2b25/bc10c/OpenHAB3_Docker_23.webp 460w", "/en/static/9e1080732d3f380b9ab08b6698ee2b25/33b41/OpenHAB3_Docker_23.webp 765w"],
              "sizes": "(max-width: 765px) 100vw, 765px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e1080732d3f380b9ab08b6698ee2b25/81c8e/OpenHAB3_Docker_23.png 230w", "/en/static/9e1080732d3f380b9ab08b6698ee2b25/08a84/OpenHAB3_Docker_23.png 460w", "/en/static/9e1080732d3f380b9ab08b6698ee2b25/bbb77/OpenHAB3_Docker_23.png 765w"],
              "sizes": "(max-width: 765px) 100vw, 765px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9e1080732d3f380b9ab08b6698ee2b25/bbb77/OpenHAB3_Docker_23.png",
              "alt": "OpenHAB 3 INSTAR Camera Binding",
              "title": "OpenHAB 3 INSTAR Camera Binding",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In my case I am using the Alpine Linux Docker image of OpenHab which does not come with FFMPEG. I will install it by entering the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`openhab`}</code>{` container (I tagged my container `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`openhab`}</code>{` - if you did not supply a tag when running the container, replace the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`openhab`}</code>{` tag with your container ID) and run the installation command: `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` -ti openhab /bin/ash
apk `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` ffmpeg
ffmpeg -version`}</code></pre></div>
    <p>{`Remember that when you remove this container and recreate it - e.g. after updating the container image - you have to re-install ffmpeg!`}</p>
    <h2 {...{
      "id": "add-an-snapshot-channel",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#add-an-snapshot-channel",
        "aria-label": "add an snapshot channel permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add an Snapshot Channel`}</h2>
    <p>{`Click save and re-enter the camera `}<strong parentName="p">{`Thing`}</strong>{` you created. Now we can continue by adding Channels and link them to `}<strong parentName="p">{`Items`}</strong>{` on our OpenHab dashboard:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "756px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4ebdc5271ac1f188f6ca9a14d23910bc/8ae3e/OpenHAB3_Docker_24.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69.56521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB70lEQVQoz11QW27bMBDUEQpYlkVSfO5yKct2bfntWLETfwUBihZNr9D7X6Eg6SRtBwtywcUMZ6eYOx2sUcZorc2/2O52D8Nj27b/jbTWnTNzZwrBOatrxrkxhiVwzpVSUqlgdQ/GqAgpZRrWQjRaa17XgvOiLMumaZSUWmsheIZIkEJo8Yk8iuqMTep6NB5HMiISkUf8MOYSjLX6vc+w1hLRtJstuo5PqmI0GmXJpmmklPmHqqrKsmSs5pyX5TgjvfBp2wKFJYGdlMV4XPUJ+/1+u93mHhGttfP5fLlcOucgwTm3WCz6vtcxQDuuJsVkUgPg4XA6ns7DMBhj0srRhxBN7lITi3PBuUgWFWMskj2F6/Xpdrv1/Xo67dCT94TxujeI3vuQ+6aR72QeydbB8Hi5XK7rzYY8gcsmwVpIljFGFfOCj0L0nPNCa/368no+D6vViihIBwIQAQEQXDwR0UEWBBeFwFoHgJHMGF/u+t3+cDwcusXXAfAFPMRKEvlr9FnFIylt8trRdjkqK2Q+hODJhPBTmt/SxKWkkgalJaldXlJKdX+XSikdycbY799+rPv+eDzNunml1Jd7tlKYVsBMaC9S/n9nfg/MWvv29uv0cH5+vl0uT9v1ZhpaQiJPETFiH0JLFPIZy5OUqq7ZHwVacSvTSUBAAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4ebdc5271ac1f188f6ca9a14d23910bc/e4706/OpenHAB3_Docker_24.avif 230w", "/en/static/4ebdc5271ac1f188f6ca9a14d23910bc/d1af7/OpenHAB3_Docker_24.avif 460w", "/en/static/4ebdc5271ac1f188f6ca9a14d23910bc/b91b2/OpenHAB3_Docker_24.avif 756w"],
              "sizes": "(max-width: 756px) 100vw, 756px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4ebdc5271ac1f188f6ca9a14d23910bc/a0b58/OpenHAB3_Docker_24.webp 230w", "/en/static/4ebdc5271ac1f188f6ca9a14d23910bc/bc10c/OpenHAB3_Docker_24.webp 460w", "/en/static/4ebdc5271ac1f188f6ca9a14d23910bc/b5834/OpenHAB3_Docker_24.webp 756w"],
              "sizes": "(max-width: 756px) 100vw, 756px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4ebdc5271ac1f188f6ca9a14d23910bc/81c8e/OpenHAB3_Docker_24.png 230w", "/en/static/4ebdc5271ac1f188f6ca9a14d23910bc/08a84/OpenHAB3_Docker_24.png 460w", "/en/static/4ebdc5271ac1f188f6ca9a14d23910bc/8ae3e/OpenHAB3_Docker_24.png 756w"],
              "sizes": "(max-width: 756px) 100vw, 756px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4ebdc5271ac1f188f6ca9a14d23910bc/8ae3e/OpenHAB3_Docker_24.png",
              "alt": "OpenHAB 3 INSTAR Camera Binding",
              "title": "OpenHAB 3 INSTAR Camera Binding",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`I choose the `}<strong parentName="p">{`Image`}</strong>{` channel and assign it to the `}<strong parentName="p">{`Semantic Property`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Presence`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "781px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dc9162ea9e1bc1173be867152cf493fa/7fee5/OpenHAB3_Docker_25.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "125.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAIAAAC+dZmEAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACkklEQVQ4y42UyY7TQBCGfQOBJrZ7d29ux1u8JnbsaK4zcEbAC4DEhScAceMVEC+M2k4yniGB+fTL6q529VJdXU4a6lwLpVS4QClV1/U4jsMwtG2rlNKXcHwAhOCMstVq5U54nuc/AwCAAyC8v39ze3u7XsepJTMmCgL+GPG3EEIOQqgoiq1lt91um3ZbllUcJ0ma2G+cJoltTPOmSioh5CyMsQPstqXruqvVjeuuvAnwPBwEUVM33a7rdl3f742JOBfn6f8hhLBDMRrSaBwO43jY94M8bWw+2H+cEUTzAZAF0wnGmJRKShUEAb3EFDBsA9b1+zTNprsMtQ6lVEVRDuNY1U2eb872WWFohJBN03LOHYRwWVZahUrpWVIqY6LQHl5Kqc7282gUrfN8gzGxK7fttqqq839SqizLq6r+23OW1mFRlJQy62z3ExrOhbQoQujd3f14OBBC5eJiz+Kcax0SMq2cpGlZlPE6nsJrUUqJK3DOp/Q3EE4ZVtVt1/VN0yqlhV1ezd9ryrI8jpNjtEMp83xTVU2aZtcyeal5CusMIZwXmk0Yk4Xw4+4jO4TQAT7Is0wrCYCPECQEE2KHiQXP3ZmT8dj2fWAfBlUxj4ogKnjcCG14cIRzHpweZxAE7GTknDPG7MOw29amqOo0yynjjAXPURAEx4DF62TY73e7ru+6LMulVMt8vCabYRAhbdbjODZN2/V9lm+EkBcT64msM0Go32TDeDAm0vohw3UY6XA9KbrqjBHcaGFMxBinlJ1Eifca37zANy+J+4oSuhg6ytYwAMDK813XXRRGD0BE7r7oD9/N+2/k7VeAme+5l6onABACCOFDafJ9gDB790N//m0+/WIff0IqgO8BAJ/UsD/Kn6XwCQlvqAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dc9162ea9e1bc1173be867152cf493fa/e4706/OpenHAB3_Docker_25.avif 230w", "/en/static/dc9162ea9e1bc1173be867152cf493fa/d1af7/OpenHAB3_Docker_25.avif 460w", "/en/static/dc9162ea9e1bc1173be867152cf493fa/76048/OpenHAB3_Docker_25.avif 781w"],
              "sizes": "(max-width: 781px) 100vw, 781px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dc9162ea9e1bc1173be867152cf493fa/a0b58/OpenHAB3_Docker_25.webp 230w", "/en/static/dc9162ea9e1bc1173be867152cf493fa/bc10c/OpenHAB3_Docker_25.webp 460w", "/en/static/dc9162ea9e1bc1173be867152cf493fa/42cfc/OpenHAB3_Docker_25.webp 781w"],
              "sizes": "(max-width: 781px) 100vw, 781px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dc9162ea9e1bc1173be867152cf493fa/81c8e/OpenHAB3_Docker_25.png 230w", "/en/static/dc9162ea9e1bc1173be867152cf493fa/08a84/OpenHAB3_Docker_25.png 460w", "/en/static/dc9162ea9e1bc1173be867152cf493fa/7fee5/OpenHAB3_Docker_25.png 781w"],
              "sizes": "(max-width: 781px) 100vw, 781px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dc9162ea9e1bc1173be867152cf493fa/7fee5/OpenHAB3_Docker_25.png",
              "alt": "OpenHAB 3 INSTAR Camera Binding",
              "title": "OpenHAB 3 INSTAR Camera Binding",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click save and additionally add a `}<strong parentName="p">{`Poll Image`}</strong>{` channel and add it to the same dashboard `}<strong parentName="p">{`Item`}</strong>{`. It allows us to start the image polling service.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f4540f8a22efb26f89a6703af60514f9/46e51/OpenHAB3_Docker_26.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABWElEQVQoz2WR23baMBBF+YP6gu3guUgayWOLQhJYkDaEPJD2pf//Q12SgV5y1nnQedhnzYwWjogpyVqLiH3fAwAzG2PYmG2c1qOmYAwRAQAiEpGxKS7q5VVd19msXEXMTMyO0BGmwDzDAPCwWrVtu1wuF9VNdV0z8wzjTZB9lxNR1YGxLMqqqq5wWZZN04zjFON6s9nEGEUEoB9VnXNzibVmt9tN0xQMVeX/cPt1s30/n0+ntxDUWndXeueYZgFcAc7Un7Gbpolxfbl87Pd7L95aZ51kVu5mpN6Fn+OzKYsvd7goCujh2/fX4/Hlcfvofcj24sSLl2ybC1jjr+mJik/wx+XH6fSmOomI+OAl+BDEB3EhhCHGtQ5qJGydtnnpv3Zu2+fD7nx+PxyOOuj8o7dzE7O5Dm8l7VzX/xys7TqjojqO40TEiURGO6AN+U0ABJCqCKnOB/sNQR5G4ZmJgNsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f4540f8a22efb26f89a6703af60514f9/e4706/OpenHAB3_Docker_26.avif 230w", "/en/static/f4540f8a22efb26f89a6703af60514f9/d1af7/OpenHAB3_Docker_26.avif 460w", "/en/static/f4540f8a22efb26f89a6703af60514f9/7f308/OpenHAB3_Docker_26.avif 920w", "/en/static/f4540f8a22efb26f89a6703af60514f9/8cad5/OpenHAB3_Docker_26.avif 1003w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f4540f8a22efb26f89a6703af60514f9/a0b58/OpenHAB3_Docker_26.webp 230w", "/en/static/f4540f8a22efb26f89a6703af60514f9/bc10c/OpenHAB3_Docker_26.webp 460w", "/en/static/f4540f8a22efb26f89a6703af60514f9/966d8/OpenHAB3_Docker_26.webp 920w", "/en/static/f4540f8a22efb26f89a6703af60514f9/2f8da/OpenHAB3_Docker_26.webp 1003w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f4540f8a22efb26f89a6703af60514f9/81c8e/OpenHAB3_Docker_26.png 230w", "/en/static/f4540f8a22efb26f89a6703af60514f9/08a84/OpenHAB3_Docker_26.png 460w", "/en/static/f4540f8a22efb26f89a6703af60514f9/c0255/OpenHAB3_Docker_26.png 920w", "/en/static/f4540f8a22efb26f89a6703af60514f9/46e51/OpenHAB3_Docker_26.png 1003w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f4540f8a22efb26f89a6703af60514f9/c0255/OpenHAB3_Docker_26.png",
              "alt": "OpenHAB 3 INSTAR Camera Binding",
              "title": "OpenHAB 3 INSTAR Camera Binding",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`On the properties tab on my dashboard I am now able to see the `}<strong parentName="p">{`Presence Property`}</strong>{`. Clicking on the `}<strong parentName="p">{`Image Item`}</strong>{` allows me to access a snapshot from my camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "841px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6a77bd7b9d8ceda9e9d1f0502e2230eb/bad1b/OpenHAB3_Docker_27.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABl0lEQVQoz4XQzU8TQRzG8ZGL7ULdnd9sZ8q+ze7OzAJqTF+2TYSdptm1xnhSE0FMKFwIXvwPNNx84c0YrNEKBwOJ/6YpEg61aPK9fpInDwIAwBg78xUl5kR0S0SWjP8brjHAGIFt41KZrj65++ukfvqFDj+ig7czh7s3pjVzuIv23rCTT9Dv4bIxxqRszK4/Nc6HMDq6+W0fDd//q+N36PRo9lFOLnHJKL18hs6O0dcPaLSPRgfox/V930M/PxuPiwtMCMxVbH3feb0Ng1Wy+YJsrVsbz/FgbWowWGOvtnDjHlQqaPwXQK1Kbwu5knZW0k596Y7wgshxeW1+Ip8yFfAHWZfZNgYYYwBcpSxWall3l7WuN1syWeBR7PFwIscPYpX08oIyhsdvX2BKmVKJ1jrLsmazpVTCeej7wUSu6wkh8zynlF5hqFapEFLrbpbpVitNkoUwjK7DRVFcYkKIaZpSyn7/YbvdWVxcEkLGsQgC/jf+E+chu5ptWZbreo1GU+tumrbDMPI8fyrzfB65zk4vDQLfNK3frw16xCJ1764AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6a77bd7b9d8ceda9e9d1f0502e2230eb/e4706/OpenHAB3_Docker_27.avif 230w", "/en/static/6a77bd7b9d8ceda9e9d1f0502e2230eb/d1af7/OpenHAB3_Docker_27.avif 460w", "/en/static/6a77bd7b9d8ceda9e9d1f0502e2230eb/f15cc/OpenHAB3_Docker_27.avif 841w"],
              "sizes": "(max-width: 841px) 100vw, 841px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6a77bd7b9d8ceda9e9d1f0502e2230eb/a0b58/OpenHAB3_Docker_27.webp 230w", "/en/static/6a77bd7b9d8ceda9e9d1f0502e2230eb/bc10c/OpenHAB3_Docker_27.webp 460w", "/en/static/6a77bd7b9d8ceda9e9d1f0502e2230eb/d02c2/OpenHAB3_Docker_27.webp 841w"],
              "sizes": "(max-width: 841px) 100vw, 841px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6a77bd7b9d8ceda9e9d1f0502e2230eb/81c8e/OpenHAB3_Docker_27.png 230w", "/en/static/6a77bd7b9d8ceda9e9d1f0502e2230eb/08a84/OpenHAB3_Docker_27.png 460w", "/en/static/6a77bd7b9d8ceda9e9d1f0502e2230eb/bad1b/OpenHAB3_Docker_27.png 841w"],
              "sizes": "(max-width: 841px) 100vw, 841px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6a77bd7b9d8ceda9e9d1f0502e2230eb/bad1b/OpenHAB3_Docker_27.png",
              "alt": "OpenHAB 3 INSTAR Camera Binding",
              "title": "OpenHAB 3 INSTAR Camera Binding",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "camera-control",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#camera-control",
        "aria-label": "camera control permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera Control`}</h2>
    <p>{`You can continue adding camera channels to take control over your camera from the OpenHab dashboard - e.g. the Pan&Tilt control:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "916px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dee530984e1167b41d0367005e3cf45d/59822/OpenHAB3_Docker_28.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABqElEQVQoz3WT226jMBCG+wiBizjB9nhsjw2EQ6ApoF2SlUIabd//iVakSmqyrfXLF5Y/zT+nl2i1Utq23Xg5T9N0mc5TURRK4VIKQAGglACAjLEoiuI4flmtVtq45vDrz/HUNE2WZbtdQeSJ3EPWkrVE5JRCKdV6fYejKBKgqubteDz2Xee8Z5ttknAh5LcCCOA4jjnnTdNer9fL5X38PTb7Nk0zAAgZKeFTz3CS8LpuLtN73w1ZlqdpZsmhQgUICm/Zqh/hbcKbfXs+T3W9N9Yiaq21NfTI1lr6giUscuZcVGXddV1Z1sZYYyyRQ9RiPs+2lfFrFkRmjBVF2batI6/RaG3Uze0DCIVFxzbbEN5UVd31g/eptaS14Vx8S87w7sDYEs7SvO97771zXin8qU9cSLSOsUXBEsr8MAxt+0rk7k0WgvMw7c9HgKBgcRRvREKFy7NdmuZfDkFLpPle2gZQi/E01lXlPvVpGEEgSVsKdE/B5dyqdTCeQlb1fhzHqppbhajngt3//h85tB0lXBzeuo+/H6fjKc9zd9sKjYZzKQQ81SxcjH/REG73VlweWQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dee530984e1167b41d0367005e3cf45d/e4706/OpenHAB3_Docker_28.avif 230w", "/en/static/dee530984e1167b41d0367005e3cf45d/d1af7/OpenHAB3_Docker_28.avif 460w", "/en/static/dee530984e1167b41d0367005e3cf45d/1e879/OpenHAB3_Docker_28.avif 916w"],
              "sizes": "(max-width: 916px) 100vw, 916px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dee530984e1167b41d0367005e3cf45d/a0b58/OpenHAB3_Docker_28.webp 230w", "/en/static/dee530984e1167b41d0367005e3cf45d/bc10c/OpenHAB3_Docker_28.webp 460w", "/en/static/dee530984e1167b41d0367005e3cf45d/3fccf/OpenHAB3_Docker_28.webp 916w"],
              "sizes": "(max-width: 916px) 100vw, 916px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dee530984e1167b41d0367005e3cf45d/81c8e/OpenHAB3_Docker_28.png 230w", "/en/static/dee530984e1167b41d0367005e3cf45d/08a84/OpenHAB3_Docker_28.png 460w", "/en/static/dee530984e1167b41d0367005e3cf45d/59822/OpenHAB3_Docker_28.png 916w"],
              "sizes": "(max-width: 916px) 100vw, 916px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dee530984e1167b41d0367005e3cf45d/59822/OpenHAB3_Docker_28.png",
              "alt": "OpenHAB 3 INSTAR Camera Binding",
              "title": "OpenHAB 3 INSTAR Camera Binding",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "886px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0973a2ad3c646793ba82ad58dd2a3dd3/cc155/OpenHAB3_Docker_29.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAqUlEQVQY012NMXLDMAwE/QWyMKkIOIAAAUtxnCb//1tmpMJRbra4ZmdvtdZSChF9vV7bvru7uesYoiqqEJU/ADqGretaSqm13uqx+72Z2f7YbAwwgRjEypzCEAHeiGjvy0VurauOh9kEUjUEU7CpfJtG5jwWke6emcvy8b88Zz4/nzYMzMJsAEEIl+xZvsillNY6ETt4gkNlE/6ZGpHzSJ3h43hEtNZP+RdyiiJsPXipDwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0973a2ad3c646793ba82ad58dd2a3dd3/e4706/OpenHAB3_Docker_29.avif 230w", "/en/static/0973a2ad3c646793ba82ad58dd2a3dd3/d1af7/OpenHAB3_Docker_29.avif 460w", "/en/static/0973a2ad3c646793ba82ad58dd2a3dd3/d297b/OpenHAB3_Docker_29.avif 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0973a2ad3c646793ba82ad58dd2a3dd3/a0b58/OpenHAB3_Docker_29.webp 230w", "/en/static/0973a2ad3c646793ba82ad58dd2a3dd3/bc10c/OpenHAB3_Docker_29.webp 460w", "/en/static/0973a2ad3c646793ba82ad58dd2a3dd3/b4685/OpenHAB3_Docker_29.webp 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0973a2ad3c646793ba82ad58dd2a3dd3/81c8e/OpenHAB3_Docker_29.png 230w", "/en/static/0973a2ad3c646793ba82ad58dd2a3dd3/08a84/OpenHAB3_Docker_29.png 460w", "/en/static/0973a2ad3c646793ba82ad58dd2a3dd3/cc155/OpenHAB3_Docker_29.png 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0973a2ad3c646793ba82ad58dd2a3dd3/cc155/OpenHAB3_Docker_29.png",
              "alt": "OpenHAB 3 INSTAR Camera Binding",
              "title": "OpenHAB 3 INSTAR Camera Binding",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      